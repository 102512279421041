import React from "react";
import { Box, Toolbar } from '@mui/material';
import Header from "shared/header";
import Footer from "shared/footer";
import { Drawer } from 'components';
import { styled, useTheme } from '@mui/material/styles';

const ContainerBody = styled(Box)(({ theme, open }) => ({
    flexGrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    ...(open && { width: `calc(100% - ${theme.drawerWidth}px)` })
}));

const Component = ({ children }) => {

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const OnDrawerOpened = (e) => { setOpen(e); };

    return (
        <>
            <Header />
            <Drawer onDrawerOpened={OnDrawerOpened} />
            <ContainerBody open={open} theme={theme}>
                <Toolbar />
                {children}
            </ContainerBody>
            <Footer />
        </>
    );
};

export default Component;