import React from "react";
import { GridActionsCellItem } from '@mui/x-data-grid';
import { Typography, Tooltip, Box, Grid } from '@mui/material';
import { EditTwoTone as EditIcon, CurrencyRupee as DoneIcon, Reply as ReplyIcon } from '@mui/icons-material';
import { UpiApi } from "services";
import Helper from "helper";
import { DataList, Dialog, InputForm } from "components";
import Container from "screens/container";
import { ValidatorForm } from 'react-material-ui-form-validator';
import EditForm from "config/editmandate.json";

const headerColumns = [
    { headerName: "Sl", field: "SlNum", width: 60 },
    { headerName: "Loan No", field: "loanNumber", width: 100 },
    { headerName: "Payer Name", field: "customerName", width: 200 },
    { headerName: "Payer Address", field: "payerAddress", width: 200 },
    { headerName: "Frequency", field: "pattern", width: 85 },
    { headerName: "EMI Amt", field: "amount", type: 'number', width: 85 },
    { headerName: "Start Date", field: "startDate", width: 85 },
    { headerName: "End Date", field: "endDate", width: 85 },
    /* { headerName: "Transaction Id", field: "txnId", flex: 1 }, */
    { headerName: "Status", field: "status", width: 85 },
    { headerName: "Description", field: "statusDesc", flex: 1.3 }
];

const Component = () => {
    const form = React.useRef(null);
    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [editRow, setEditRow] = React.useState([]);
    const [updateRow, setUpdateRow] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [pageInfo, setPageInfo] = React.useState({ page: 0, pageSize: 10 });
    const [dialogState, setDialogState] = React.useState({ key: null, revoke: false, execute: false, modify: false });

    const FetchResults = async () => {
        global.Busy(true);
        setRows([]);
        setRowsCount(0);
        setColumns([]);
        let cols = [];
        headerColumns.forEach((x) => { x.sortable = false; cols.push(x); });
        setColumns(cols);
        let req = { page: pageInfo.page + 1, size: pageInfo.pageSize };
        let rslt = await UpiApi.GetMandateList(req);
        if (rslt.status === 100) {
            let _tmp = rslt.data;
            setRowsCount(_tmp.recordCount);
            _tmp = _tmp.rows;
            for (let i = 0; i < _tmp.length; i++) {
                _tmp[i].id = Helper.GetGUID();
                _tmp[i].SlNum = Helper.Padding(i + (pageInfo.page * pageInfo.pageSize) + 1, 5);
            }
            setRows(_tmp);
        }
        global.Busy(false);

        for (let prop of EditForm) {
            delete prop['value'];
        }

        setEditRow(EditForm);
    };

    const OnActionClicked = (e, row) => {
        let tState = { key: row._id, revoke: false, execute: false, modify: false };
        tState[e] = true;
        if (tState.modify) LoadValues(row);
        setDialogState(tState);
    }

    const LoadValues = (row) => {
        for (let prop of EditForm) {
            prop['value'] = row[prop.key];
            OnInputChange({ name: prop.key, value: prop.value });
        }
        setEditRow(EditForm);
    }

    const RenderGridActions = () => {

        return {
            headerName: "Actions", type: 'actions', field: "actions", width: 115,
            getActions: ({ row }) => {

                const status = row.status || "";

                const actions = [];

                const noactions = ['PENDING', 'REVOKED', 'FAILED'];

                if (noactions.indexOf(status.toUpperCase()) > -1) return actions;

                if (status.toUpperCase() === 'ACTIVE') {

                    actions.push(<GridActionsCellItem
                        key={1}
                        icon={
                            <Tooltip title="Modify" arrow>
                                <EditIcon sx={{
                                    color: "#757575"
                                }} onClick={() => OnActionClicked('modify', row)} />
                            </Tooltip>
                        }
                        label="Modify"
                        className="textPrimary"
                        color="inherit"
                    />);

                    actions.push(<GridActionsCellItem
                        key={2}
                        icon={
                            <Tooltip title="Revoke" arrow>
                                <ReplyIcon sx={{
                                    color: "#757575"
                                }} onClick={() => OnActionClicked('revoke', row)} />
                            </Tooltip>
                        }
                        label="Revoke"
                        color="inherit"
                    />);

                    actions.push(<GridActionsCellItem
                        key={3}
                        icon={
                            <Tooltip title="Execute" arrow>
                                <DoneIcon sx={{
                                    color: "#757575"
                                }} onClick={() => OnActionClicked('execute', row)} />
                            </Tooltip>
                        }
                        label="Execute"
                        className="textPrimary"
                        color="inherit"
                    />);

                }

                return actions;
            }
        };

    }

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    const OnPageChange = async (e) => {
        setPageInfo(e);
        setInitlized(true);
    }

    const OnRevokeConfirmed = async (id) => {
        global.Busy(true);
        let rslt = await UpiApi.RevokeMandate(id);
        global.Busy(false);
        console.log(rslt);
        if (rslt.status !== 100) {
            global.AlertPopup("error", rslt.statusText);
        } else {
            global.AlertPopup("success", rslt.statusText);
            setInitlized(true);
        }
    }

    const OnExecuteConfirmed = async (id) => {
        global.Busy(true);
        let rslt = await UpiApi.MandateExecute(id);
        global.Busy(false);
        console.log(rslt);
        if (rslt.status !== 100) {
            global.AlertPopup("error", rslt.statusText);
        } else {
            global.AlertPopup("success", rslt.statusText);
            setInitlized(true);
        }
    }

    const OnCloseClicked = async (e) => {
        let tState = { ...dialogState };
        if (!tState.modify) setDialogState({ key: null, revoke: false, execute: false, modify: false });
        if (e) {
            if (tState.revoke) OnRevokeConfirmed(tState.key);
            if (tState.execute) OnExecuteConfirmed(tState.key);
            if (tState.modify) form.current.submit();
        } else {
            if (tState.modify) setDialogState({ key: null, revoke: false, execute: false, modify: false });
        }
    }

    const ToDateFormat = (v) => {
        const splits = v.split('-');
        return `${splits[2]}${splits[1]}${splits[0]}`;
    }

    const handleSubmit = async () => {
        let obj = updateRow;
        let oldObj = rows.find((x) => x._id === dialogState.key);
        const pattern = oldObj.pattern;
        obj._id = oldObj._id;
        obj.startDate = ToDateFormat(obj.dateOn);
        SetRuleValue(obj, pattern);
        global.Busy(true);
        let rslt = await UpiApi.ModifyMandate(obj);
        global.Busy(false);
        if (rslt.status !== 100) {
            global.AlertPopup("error", rslt.statusText);
        } else {
            setDialogState({ key: null, revoke: false, execute: false, modify: false });
            global.AlertPopup("success", rslt.statusText);
            setInitlized(true);
        }
    }

    const SetRuleValue = (obj, pattern) => {
        let sdate = obj?.dateOn;

        if (!Helper.IsNullValue(pattern) && !Helper.IsNullValue(sdate)) {
            let rValue = "";
            var d = new Date(sdate);
            if (pattern === 'WEEKLY') {
                var days = {
                    'monday': 1, 'tuesday': 2, 'wednesday': 3,
                    'thursday': 4, 'friday': 5, 'saturday': 6, 'sunday': 7
                };
                const dName = rValue = Helper.ToDateFormat(sdate, "dddd");
                rValue = days[dName.toLowerCase()];
            } else if (pattern === 'FORTNIGHTLY') {
                if (d.getDate() > 0 && d.getDate() < 16) {
                    rValue = d.getDate();
                } else if (d.getDate() > 15 && d.getDate() < 32) {
                    rValue = d.getDate() - 15;
                }
            } else {
                rValue = obj.dateOn.split('-')[2];
            }

            obj.ruleValue = rValue;
        }
    };

    const OnInputChange = (e) => {
        const { name, value } = e;
        setUpdateRow((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const OnFormValidationError = (e) => {
        if (e && e.length > 0) {
            global.AlertPopup("error", "Fill all mandatory fields!");
        }
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    return (
        <>
            <Container>
                <Typography variant="headerText">E-Mandate List</Typography>
                <DataList
                    sx={{ mt: 1 }}
                    columns={columns}
                    rowsCount={rowsCount}
                    rows={rows}
                    pageInfo={pageInfo}
                    onPageChange={OnPageChange}
                    gridActions={RenderGridActions()}
                />
                <Dialog open={dialogState.revoke} title={"Revoke"} onCloseClicked={OnCloseClicked}>
                    <Typography gutterBottom>
                        Are you sure? You want to revoke?
                    </Typography>
                </Dialog>

                <Dialog open={dialogState.execute} title={"Excute"} onCloseClicked={OnCloseClicked}>
                    <Typography gutterBottom>
                        Are you sure? You want to execute?
                    </Typography>
                </Dialog>

                <Dialog width="auto" open={dialogState.modify} title={"Modify"} onCloseClicked={OnCloseClicked}>
                    <Box sx={{
                        width: '100%', p: 1, pl: 4, mr: 0, pr: 0, borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.23)"
                    }}>
                        <ValidatorForm onError={(e) => OnFormValidationError(e)} ref={form} onSubmit={handleSubmit}>
                            <Grid container rowGap={2} columnGap={2}>
                                {editRow.map((x) => (
                                    <InputForm xs={5} key={x.key} row={x} onInputChange={OnInputChange} />
                                ))}
                            </Grid>
                        </ValidatorForm>
                    </Box>
                </Dialog>

            </Container>
        </>
    )
}

export default Component;