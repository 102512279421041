import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
let fn = {};

let hostname = window.location.hostname;

let prodItems = ["www.upi.mohamcapital.in", "upi.mohamcapital.com"];
let stageItems = ["www.upi.mohamcapital.in", "upi.mohamcapital.in"];

if (hostname === "localhost") {
    //fn.APIUrl = "http://localhost:4000/";
    fn.APIUrl = "https://core.mohamcapital.in/";
} else if (prodItems.findIndex((x) => x === hostname) > -1) {
    fn.APIUrl = "https://core.mohamcapital.in/";
} if (stageItems.findIndex((x) => x === hostname) > -1) {
    fn.APIUrl = "https://core.mohamcapital.in/";
}

fn.GetGUID = () => {
    return uuidv4().replace(/-/g, '');
}

fn.Padding = (val, len) => {
    return ("0".repeat(len) + val).slice(-len);
};

fn.IsNull = (e) => {
    if (e === undefined || e === null) return true;
    return false;
};

fn.IsNullValue = (e) => {
    if (e === undefined || e === null || e === "") return true;
    return false;
};

fn.IsJSONEmpty = (e) => {
    if (fn.IsNull(e)) return true;
    for (var key in e) {
        if (Object.prototype.hasOwnProperty.call(e, key)) {
            return false;
        }
    }
    return true;
};

fn.ToDateFormat = (e, format, utc) => {
    if (fn.IsNullValue(e)) return "";
    if (fn.IsNullValue(format)) return moment(new Date(e));
    if (utc) return moment(e).utc().format(format);
    return moment(new Date(e)).format(format);
};


export default fn;