import API from "./api";
import Session from "shared/session";

const GetMandateList = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`upi/list`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const CreateMandate = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`upi/create`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const RevokeMandate = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`upi/revoke/${input}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const MandateExecute = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`upi/recurring/${input}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const ModifyMandate = async (input) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.post(`upi/modify`, input, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const GetLoanByLoanNumber = async (num) => {
    try {
        const token = await Session.GetHeader();
        const res = await API.get(`loan/short/number/${num}`, {
            headers: token,
        });
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

const SingIn = async (input) => {
    try {
        const res = await API.post(`auth/admin`, input);
        return res.data;
    } catch (err) {
        return { statusText: err.message, status: 900 };
    }
};

export {
    SingIn, GetMandateList, CreateMandate, GetLoanByLoanNumber,
    RevokeMandate, ModifyMandate, MandateExecute
};
