var fn = {};

/* Session Start */
fn.Store = (key, value, json) => {
    if (json) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
    else {
        sessionStorage.setItem(key, value);
    }
};

fn.StoreAsync = async (key, value, json) => {
    return new Promise(async (resolve) => {
        fn.Store(key, value, json);
        return resolve(true);
    });
};

fn.Retrieve = (key, parse) => {
    if (parse) {
        return JSON.parse(sessionStorage.getItem(key));
    }
    else {
        return sessionStorage.getItem(key);
    }
};

fn.RetrieveAsync = async (key, parse) => {
    return new Promise(async (resolve) => {
        return resolve(fn.Retrieve(key, parse));
    });
};

fn.Remove = (key) => {
    return sessionStorage.removeItem(key);
};

fn.RemoveAsync = async (key) => {
    return new Promise(async (resolve) => {
        return resolve(sessionStorage.removeItem(key));
    });
};

fn.GetHeaderAsync = async () => {
    const token = await fn.RetrieveAsync("userToken");
    return {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
    };
};

fn.ToQueryString = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

fn.GetLoanIdAsync = async () => {
    let value = await fn.RetrieveAsync("loanid");
    return parseInt(value);
};

fn.GetUserIdAsync = async () => {
    let value = await fn.RetrieveAsync("user");
    if (value) {
        value = JSON.parse(value);
        return value._id;
    }
    return null;
};

fn.GetHeader = async () => {
    const token = await fn.RetrieveAsync("userToken");
    return {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
    };
};


export default fn;
