import React from "react";
import { Typography } from '@mui/material';
import { UpiApi } from "services";
import Helper from "helper";
import { DataList } from "components";
import Container from "screens/container";

const headerColumns = [
    { headerName: "Sl", field: "SlNum", width: 60 },
    { headerName: "Loan No", field: "loanNumber", width: 100 },
    { headerName: "Payer Name", field: "customerName", width: 200 },
    { headerName: "Payer Address", field: "payerAddress", width: 200 },
    { headerName: "Frequency", field: "pattern", width: 85 },
    { headerName: "EMI Amt", field: "amount", type: 'number', width: 85 },
    { headerName: "Start Date", field: "startDate", width: 85 },
    { headerName: "End Date", field: "endDate", width: 85 },
    /* { headerName: "Transaction Id", field: "txnId", flex: 1 }, */
    { headerName: "Status", field: "status", width: 85 },
    { headerName: "Description", field: "statusDesc", flex: 1.3 }
];

const Component = () => {

    const [initlized, setInitlized] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [rowsCount, setRowsCount] = React.useState(0);
    const [pageInfo, setPageInfo] = React.useState({ page: 0, pageSize: 10 });

    const FetchResults = async () => {
        global.Busy(true);
        setRows([]);
        setRowsCount(0);
        setColumns([]);
        let cols = [];
        headerColumns.forEach((x) => { x.sortable = false; cols.push(x); });
        setColumns(cols);
        let req = { page: pageInfo.page + 1, size: pageInfo.pageSize };
        let rslt = await UpiApi.GetMandateList(req);
        if (rslt.status === 100) {
            let _tmp = rslt.data;
            setRowsCount(_tmp.recordCount);
            _tmp = _tmp.rows;
            for (let i = 0; i < _tmp.length; i++) {
                _tmp[i].id = Helper.GetGUID();
                _tmp[i].SlNum = Helper.Padding(i + (pageInfo.page * pageInfo.pageSize) + 1, 5);
            }
            setRows(_tmp);
        }
        global.Busy(false);
    };

    if (initlized) {
        setInitlized(false);
        FetchResults();
    }

    React.useEffect(() => {
        setInitlized(true);
    }, []);

    const OnPageChange = async (e) => {
        setPageInfo(e);
        setInitlized(true);
    }

    return (
        <>
            <Container>
                <Typography variant="headerText">Mandate Failures</Typography>
                <DataList
                    sx={{ mt: 1 }}
                    columns={columns}
                    rowsCount={rowsCount}
                    rows={rows}
                    pageInfo={pageInfo}
                    onPageChange={OnPageChange}
                />
            </Container>
        </>
    )
}

export default Component;