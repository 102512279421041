import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "screens/login";
import MandateList from "screens/mandatelist";
import AddMandate from "screens/addmandate";
import Execute from "screens/execution";
import Failure from "screens/failure";
import Notification from "screens/notification";
import TimerSession from "shared/useTimerSession";

const RouteLinks = [
    { id: 1, path: "", Component: Login, roles: ["admin", "nach"] },
    { id: 2, path: "lnach", Session: true, Component: MandateList, roles: ["admin", "nach"] },
    { id: 3, path: "anach", Session: true, Component: AddMandate, roles: ["admin", "nach"] },
    { id: 4, path: "enach", Session: true, Component: Execute, roles: ["admin"] },
    { id: 5, path: "fnach", Session: true, Component: Failure, roles: ["admin"] },
    { id: 6, path: "nnach", Session: true, Component: Notification, roles: ["admin"] },
];

function PrivateRoute({ children }) {
    let loggedin = sessionStorage.getItem("user");
    return loggedin ? children : <Navigate to="/" />;
}

const RendorRoutes = (props) => {
    const [role] = TimerSession("role");
    let newRoutes = [];
    if (!role) {
        newRoutes = RouteLinks.filter((x) => x.id === 1);
    } else {
        newRoutes = RouteLinks.filter((x) => x.roles.indexOf(role) > -1);
    }
    if (newRoutes.length === 0) {
        return <Navigate to="/" />;
    }
    return (
        <Routes>
            {newRoutes.map((x) => {
                if (x.Session) {
                    return <Route key={x.id} path={"/" + x.path} exact
                        element={
                            <PrivateRoute>
                                <x.Component {...props} />
                            </PrivateRoute>
                        }
                    />
                } else {
                    return <Route key={x.id} path={"/" + x.path} exact
                        element={<x.Component  {...props} />} />
                }
            })}
        </Routes>
    );
};

export default RendorRoutes;