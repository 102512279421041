import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Drawer as MuiDrawer, Toolbar, List, ListItem, IconButton,
    ListItemButton, ListItemIcon, ListItemText, Tooltip
} from '@mui/material';
import Session from "shared/session";
import { styled, useTheme } from '@mui/material/styles';
import TimerSession from "shared/useTimerSession";

import {
    List as ListIcon, Add as AddIcon, Commit as CommitIcon,
    SmsFailed as SmsFailedIcon, NotificationsPaused as NotificationsPausedIcon,
    ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon
} from '@mui/icons-material';

const mnuItems = [
    { id: 1, title: 'Mandates List', link: "/lnach", roles: ["admin", "nach"], icon: <ListIcon fontSize="medium" /> },
    { id: 2, title: 'Add Mandate', link: "/anach", roles: ["admin", "nach"], icon: <AddIcon fontSize="medium" /> },
    { id: 3, title: 'Mandates Executions', link: "/enach", roles: ["admin"], icon: <CommitIcon fontSize="medium" /> },
    { id: 4, title: 'Mandate Failures', link: "/fnach", roles: ["admin"], icon: <SmsFailedIcon fontSize="medium" /> },
    { id: 5, title: 'Pre Debit Notifications', link: "/nnach", roles: ["admin"], icon: <NotificationsPausedIcon fontSize="medium" /> }
];

const openedMixin = (theme) => ({
    width: theme.drawerWidth,
    border: 0,
    borderRight: `dashed ${theme.appBar.backgroundColor} 1px`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    border: 0,
    borderRight: `dashed ${theme.appBar.backgroundColor} 1px`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const CustomDrawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: theme.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const DrawerOpener = styled("div")(({ theme, open }) => ({
    width: "24px", height: "24px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    border: "1px dashed grey",
    top: "60px",
    left: open ? theme.drawerWidth - 12 : 51,
    position: "absolute",
    zIndex: theme.appBar.zIndex + 100,
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    transition: theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));


const DrawerItems = ({ open }) => {

    const navigateTo = useNavigate();
    const loc = useLocation().pathname;
    const [role] = TimerSession("role");
    let newMnuItems = [];

    if (role) {
        newMnuItems = mnuItems.filter((x) => x.roles.indexOf(role) > -1);
    }

    return (
        <List sx={{ padding: 1 }}>
            {newMnuItems.map((x) => (
                <Tooltip key={x.id} title={!open && x.title}>
                    <ListItem disablePadding onClick={() => navigateTo(x.link)} sx={{
                        margin: "1px",
                        borderRadius: 1,
                        "&:hover": {
                            backgroundColor: "#004466",
                            color: "#ffffff",
                            "& .MuiListItemIcon-root": {
                                color: "#ffffff",
                            }
                        }
                    }}>
                        <ListItemButton selected={loc === x.link} sx={{
                            minHeight: 48,
                            borderRadius: 1,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            "&.Mui-selected": {
                                backgroundColor: "#156d9e",
                                color: "#ffffff",
                                "& .MuiListItemIcon-root": {
                                    color: "#ffffff",
                                }
                            }
                        }}>
                            <ListItemIcon sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center'
                            }}>
                                {x.icon}
                            </ListItemIcon>
                            {open && <ListItemText primary={x.title} />}
                        </ListItemButton>
                    </ListItem>
                </Tooltip>
            ))}
        </List>
    );
}

const Component = ({ onDrawerOpened }) => {
    const [open, setOpen] = React.useState(false);

    const theme = useTheme();

    const OnDrawerOpened = (e) => {
        if (onDrawerOpened) onDrawerOpened(e);
        Session.Store('open', e);
        setOpen(e);
    };

    return (
        <>
            <CustomDrawer variant="permanent"
                open={open} anchor="left" theme={theme}>
                <Toolbar />
                <DrawerItems open={open} />
            </CustomDrawer>
            <DrawerOpener open={open} >
                <IconButton
                    size="small"
                    edge="start"
                    color="inherit"
                    sx={{ width: "24px", height: "24px" }}
                    onClick={() => OnDrawerOpened(!open)}
                >
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </DrawerOpener>
        </>
    );
};

export default Component;