import React from "react";
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Box, Grid, Typography, Button } from '@mui/material';
import RequestForm from "config/requestform.json";
import Container from "screens/container";
import Helper from "helper";
import { UpiApi } from "services";
import { useNavigate } from "react-router-dom";
import { InputForm } from "components";

const defaultObject = {
    actionType: "CREATE",
    purposeCode: 14,
    purpose: "Recurring Mandate",
    revokeable: "Y"
};

const Component = () => {

    const NavigateTo = useNavigate();
    const form = React.useRef(null);
    const [initialized, setInitialized] = React.useState(false);
    const [validateLoan, setValidateLoan] = React.useState(false);
    const [rows, setRows] = React.useState([]);
    const [newRow, setNewRow] = React.useState(defaultObject);

    const ToDateFormat = (v) => {
        const splits = v.split('-');
        return `${splits[2]}${splits[1]}${splits[0]}`;
    }

    const handleSubmit = async () => {
        let obj = newRow;
        obj.startDate = ToDateFormat(obj.dateOn);
        obj.endDate = ToDateFormat(obj.dateOff);
        SetRuleValue(obj);
        global.Busy(true);
        let rslt = await UpiApi.CreateMandate(obj);
        global.Busy(false);
        if (rslt.status !== 100) {
            global.AlertPopup("error", rslt.statusText);
        } else {
            global.AlertPopup("success", rslt.statusText);
            NavigateTo("/lnach");
        }
    }

    const SetRuleValue = (obj) => {

        let pattern = obj?.pattern;
        let sdate = obj?.dateOn;

        if (!Helper.IsNullValue(pattern) && !Helper.IsNullValue(sdate)) {
            let rValue = "";
            var d = new Date(sdate);
            if (pattern === 'WEEKLY') {
                var days = {
                    'monday': 1, 'tuesday': 2, 'wednesday': 3,
                    'thursday': 4, 'friday': 5, 'saturday': 6, 'sunday': 7
                };
                const dName = rValue = Helper.ToDateFormat(sdate, "dddd");
                rValue = days[dName.toLowerCase()];
            } else if (pattern === 'FORTNIGHTLY') {
                if (d.getDate() > 0 && d.getDate() < 16) {
                    rValue = d.getDate();
                } else if (d.getDate() > 15 && d.getDate() < 32) {
                    rValue = d.getDate() - 15;
                }
            } else {
                rValue = obj.dateOn.split('-')[2];
            }

            obj.ruleValue = rValue;
        }
    };

    const OnInputBlur = (e) => {
        const { name } = e;
        if (name === 'loanNumber') setValidateLoan(true);
    }

    const OnSubmitForm = (e) => {
        e.preventDefault();
        form.current.submit();
    }

    const OnInputChange = (e) => {
        const { name, value } = e;
        setNewRow((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const OnFormValidationError = (e) => {
        if (e && e.length > 0) {
            global.AlertPopup("error", "Fill all mandatory fields!");
        }
    }

    const OnCancelClicked = (e) => {
        e.preventDefault();
        console.log(form.current);
    }

    const ValidateLoanNumber = async () => {
        if (Helper.IsNullValue(newRow.loanNumber)) return;
        global.Busy(true);
        let rslt = await UpiApi.GetLoanByLoanNumber(newRow.loanNumber);
        global.Busy(false);
        if (rslt.status !== 100) {
            MakeValid("loanNumber", true);
        } else {
            MakeValid("loanNumber");
            const data = rslt.data;
            let lastRow = newRow || {};
            lastRow['loanId'] = data._id;
            lastRow['loanNumber'] = data.loanNumber;
            lastRow['customerName'] = data.customerName;
            lastRow['customerMobile'] = data.customerMobile;
            setNewRow(lastRow);
        }
    }

    const MakeValid = (prop, invalid) => {
        let tmp = form.current.childs.find((x) => x.props.id === prop);
        if (tmp) {
            if (invalid) {
                form.current.errors.push(tmp);
                tmp.invalid.push(1);
                tmp.makeInvalid();
            } else {
                if (form.current.errors.length > 0) {
                    let _tmp = form.current.errors.findIndex((x) => x.props.id === prop);
                    if (_tmp > -1) delete form.current.errors[_tmp];
                }
                tmp.invalid = [];
                tmp.makeValid();
            }
        }
    }

    if (initialized) {
        setInitialized(false);
        for (let prop of RequestForm) {
            delete prop['value'];
        }
        setRows(RequestForm);
    }

    if (validateLoan) {
        setValidateLoan(false);
        ValidateLoanNumber();
    }

    React.useEffect(() => {
        setInitialized(true);
    }, []);

    return (
        <>
            <Container>
                <Typography variant="headerText">Request UPI E-Mandate</Typography>
                <Box sx={{ width: '100%', padding: 1, borderRadius: 1, border: "1px solid rgba(0, 0, 0, 0.23)" }}>
                    <ValidatorForm onError={(e) => OnFormValidationError(e)} ref={form} onSubmit={handleSubmit}>
                        <Grid container rowGap={2} columnGap={2}>
                            {rows.map((x) => (
                                <InputForm key={x.key} row={x} onInputChange={OnInputChange} onInputBlur={OnInputBlur} />
                            ))}
                        </Grid>
                    </ValidatorForm>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Grid container sx={{ flex: 1, alignItems: "center", justifyContent: 'flex-start', gap: 1, pt: 1, pb: 1 }}>
                        <Button variant="submit" onClick={(e) => OnSubmitForm(e)}>Submit</Button>
                        <Button variant="cancel" onClick={(e) => OnCancelClicked(e)}>Cancel</Button>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default Component;