import React from 'react';
import { TextInput, DropDown, OptionItems } from "components";
import { Grid, Typography } from '@mui/material';
import * as Support from "shared/support";

const Component = ({ row, onInputChange, onInputBlur, xs }) => {

    const tXS = xs || 2;

    const OnInputChange = (e) => {
        if (onInputChange) onInputChange(e);
    }
    const OnInputBlur = (e) => {
        if (onInputBlur) onInputBlur(e);
    }

    return (
        <>
            <Grid item xs={tXS}>
                <Typography noWrap variant="labelHeader" component="div">
                    {row.label}
                </Typography>
                {['text', 'amount', 'date'].indexOf(row.type) > -1 &&
                    <TextInput id={row.key} type={row.type} name={row.key} placeHolder={row.label} value={row.value} validators={row.validators}
                        validationMessages={row.validationMessages} OnInputChange={OnInputChange} OnInputBlur={OnInputBlur} size="small" />
                }

                {row.type === 'list' &&
                    <DropDown id={row.key} name={row.key} placeHolder={row.label} value={row.value} validators={row.validators}
                        validationMessages={row.validationMessages} onDropDownChange={OnInputChange} options={Support[row.source]} />
                }

                {row.type === 'option' &&
                    <OptionItems id={row.key} name={row.key} placeHolder={row.label} value={row.value} validators={row.validators}
                        validationMessages={row.validationMessages} OnInputChange={OnInputChange} options={Support[row.source]} />
                }
            </Grid>
        </>
    );
}

export default Component;