import * as React from "react";
import { TextValidator } from 'react-material-ui-form-validator';
import { NumericFormat } from 'react-number-format';

const NumericFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
            prefix="₹ "
            decimalScale={2}
            fixedDecimalScale={true}
        />
    );
});

const Component = (props) => {

    const { type, id, name, placeHolder, value, OnInputBlur,
        OnInputChange, style, sx, validators, validationMessages } = props;

    const [inputValue, setInputValue] = React.useState(value);
    const tType = type === 'amount' ? 'text' : type;

    let inputProps = null;
    if (type === 'amount') {
        inputProps = { inputComponent: NumericFormatCustom };
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (OnInputBlur) OnInputBlur({ name, value });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue(value);
        if (OnInputChange) OnInputChange({ name, value });
    }

    return (
        <>
            <TextValidator
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                id={id}
                size="small"
                type={tType}
                InputProps={inputProps}
                name={name}
                value={inputValue || ""}
                validators={validators}
                errorMessages={validationMessages}
                InputLabelProps={{ shrink: false }}
                placeholder={placeHolder}
                fullWidth
                style={{
                    width: "100%",
                    ...style
                }}
                sx={{
                    "& label": {
                        display: "none"
                    },
                    ...sx
                }}
            />
        </>
    );
}

export default Component;
