import React from 'react';
import {
    AppBar, Box, Toolbar, CssBaseline, Divider, Menu, MenuItem,
    Typography, Tooltip, IconButton, Badge, Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import {
    Settings as SettingsIcon, Notifications as NotificationsIcon,
    ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon
} from '@mui/icons-material';
import Session from "shared/session";

const Component = () => {
    const theme = useTheme();
    const [anchorRef, setAnchorRef] = React.useState(null);
    const open = Boolean(anchorRef);
    const NavigateTo = useNavigate();

    const handleClick = (e) => {
        setAnchorRef(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorRef(null);
    };

    const handleLogout = () => {
        setAnchorRef(null);
        Session.Remove("open");
        Session.Remove("user");
        Session.Remove("role");
        Session.Remove("userToken");
        NavigateTo("/");
    };


    return (
        <>
            <CssBaseline />
            <AppBar position="fixed" style={{ ...theme.appBar }}>
                <Toolbar disableGutters sx={{ width: "100%", minHeight: "50px !important", height: "50px" }}>
                    <Box component={"img"}
                        sx={{ paddingRight: "25px", paddingLeft: "5px", height: "45px" }}
                        src={`${theme.bgimages.logoWhite}`} />
                    <Typography noWrap component="div" variant='headerText' sx={{ flexGrow: 1 }}>Collection Management System</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: 0.2,
                            alignItems: 'center',
                            justifyContent: "right",
                            '& svg': {
                                m: 0.5,
                            },
                            '& hr': {
                                mx: 0.5,
                            },
                            marginRight: 1
                        }}
                    >
                        <Tooltip title="Settings">
                            <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                aria-label="settings">
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Notifications">
                            <IconButton
                                size="small"
                                edge="start"
                                color="inherit"
                                aria-label="notifications">
                                <Badge badgeContent={0} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem sx={{ border: "1px dashed #9e9e9e" }} />
                        <Box sx={{ display: "flex", padding: "0px 5px 0px 13px", flexDirection: "column" }}>
                            <Typography noWrap variant='userInfo' component="div" sx={{ flexGrow: 1 }}>Preetham John</Typography>
                            <Box sx={{ display: "flex", alignItems: "center", padding: 0, flexDirection: "row" }}>
                                <Button
                                    variant='profile'
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{
                                        "& .MuiButton-endIcon": {
                                            marginLeft: 0
                                        }
                                    }}
                                    endIcon={open ?
                                        <ArrowDropUpIcon size="small" /> :
                                        <ArrowDropDownIcon size="small" />}
                                >
                                    Process Associate
                                </Button>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorRef}
                                    open={open}
                                    onClose={handleClose}
                                    sx={{ zIndex: 2500, top: -1 }}
                                >
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </Box>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Component;