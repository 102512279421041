import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Paper } from '@mui/material';

const Component = () => {
    const theme = useTheme();
    return (
        <Paper sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            marginTop: 'calc(10% + 60px)',
            position: 'fixed',
            bottom: 0,
            color: "#ffffff",
            fontSize: "0.8125em",
            fontFamily: "arial",
            ...theme.appBar
        }} component="footer" square variant="outlined">
            Moham Capital Pvt Ltd © {new Date().getFullYear()} | All rights reserved.
        </Paper>
    )
};

export default Component;