import React from "react";
import { Box, Stack, Typography, Button, Link, Paper, Breadcrumbs, Tooltip, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextInput } from "components";
import { UpiApi } from "services";
import Session from "shared/session";
import { useNavigate } from "react-router-dom";

const LeftImages = styled(Box)(({ theme, height, src }) => ({
    height: height,
    backgroundImage: src && `url(${theme.bgimages[src]})`,
    backgroundSize: 350,
    backgroundRepeat: "no-repeat"
}));

const BoxCenterContainer = ({ children, sx }) => {
    return (
        <Box sx={{
            display: "flex", flexDirection: "column", flex: 1,
            justifyContent: "center", alignItems: "center", minHeight: "100vh",
            ...sx
        }}>
            {children}
        </Box>
    )
}

const SideLeft = ({ theme }) => {
    return (
        <Box sx={{
            width: 350,
            height: "100%",
            backgroundColor: theme.colors.loginLeftBgColor,
            backgroundImage: `url(${theme.bgimages.bgTexure})`,
            backgroundRepeat: "no-repeat",
            display: { xs: 'none', sm: 'block' }
        }}>
            <Stack direction="column" height="100%">
                <LeftImages height={142} src={'loginTop'} />
                <Box sx={{
                    height: "calc(100% - 257px)"
                }}>
                    <Box sx={{
                        display: "flex", flexDirection: "column",
                        justifyItems: "center", alignItems: "center"
                    }}>
                        <Typography variant="loginheader">MCPL Admin Portal</Typography>
                        <Box sx={{
                            width: 40, margin: "15px auto", height: 5,
                            borderRadius: 10, backgroundColor: theme.colors.white
                        }} />
                        <Typography variant="loginContent">A place for the MCPL Process Executive to track Loan Applications, Efficiently Managing Financial Processes and Streamlining Operations</Typography>
                    </Box>
                </Box>
                <LeftImages height={115} src={'loginBottom'} />
            </Stack>
        </Box>
    );
}

const SideRight = ({ theme }) => {
    const [row, setRow] = React.useState({});
    const NavigateTo = useNavigate();

    const OnInputChange = (e) => {
        setRow((prev) => ({
            ...prev,
            [e.name]: e.value
        }));
    }

    const handleSubmit = async () => {
        global.Busy(true);
        let rslt = await UpiApi.SingIn(row);
        global.Busy(false);
        if (rslt.status !== 100) {
            global.AlertPopup("error", rslt.statusText);
        } else {
            Session.Store("user", rslt.data.user);
            Session.Store("role", rslt.data.role);
            Session.Store("userToken", rslt.data.token);
            NavigateTo("/lnach");
        }
    }

    return (
        <Box sx={{
            width: "calc(100% - 350px)",
            height: "100%",
            padding: "0px 25px"
        }}>
            <Box
                sx={{
                    height: 75,
                    margin: "20px 0px 30px 0px",
                    backgroundImage: `url(${theme.bgimages.logoColor})`,
                    backgroundRepeat: "no-repeat"
                }}
            />
            <ValidatorForm autoComplete="off" onSubmit={handleSubmit} >
                <Box sx={{ margin: "20px 0px 5px 0px" }}><Typography variant="labelHeader">Email Address</Typography></Box>
                <TextInput id={'email'} name={'email'} value={row.email} placeholder="Email Address"
                    validators={['required', 'isEmail']} validationMessages={['Email is required', 'Email is not valid']} OnInputChange={OnInputChange} />
                <Box sx={{ margin: "20px 0px 5px 0px" }}><Typography variant="labelHeader">Password</Typography></Box>
                <TextInput type="password" id={'password'} name={'password'} value={row.password} placeholder="Password"
                    validators={['required']} validationMessages={['Password is required']} OnInputChange={OnInputChange} />
                <Button fullWidth type="submit" sx={{
                    backgroundColor: "#C21200", color: "#ffffff", mt: 2,
                    "&:hover": {
                        backgroundColor: "#e01919"
                    }
                }}>Continue</Button>
            </ValidatorForm>
        </Box>
    );
}

const Component = () => {

    const theme = useTheme();

    const OnLinkClicked = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <BoxCenterContainer>
                <Box sx={{
                    width: 750, height: 400,
                    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.25)",
                    backgroundColor: theme.colors.white
                }}>
                    <Stack direction="row" height="100%">
                        <SideLeft theme={theme} />
                        <SideRight theme={theme} />
                    </Stack>
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                    <Typography variant="poweredBy">Powered By:<Link underline="none"
                        color={theme.colors.offWhite} target="_blank"
                        sx={{ '&:hover': { color: theme.colors.red } }}
                        href="https://uttarana.com">Moham Uttarana Pvt Ltd</Link></Typography>
                </Box>
            </BoxCenterContainer>
            <Paper sx={{
                display: "flex",
                width: "100%",
                alignContent: "center",
                marginTop: 'calc(10% + 60px)',
                position: 'fixed',
                bottom: 0,
                fontSize: "0.8125em",
                fontFamily: "arial",
                backgroundColor: theme.colors.white,
                height: "30px"
            }} component="footer" square variant="outlined">
                <Grid container>
                    <Grid item xs={6} md={8}>
                        <Breadcrumbs separator="|" aria-label="breadcrumb" sx={{ marginLeft: "10px" }}>
                            <Tooltip title={"Privacy Policy"}>
                                <Link variant="footerLink"
                                    onClick={(e) => OnLinkClicked(e)}
                                    color={theme.colors.colorBlackbrownLight} underline="none" href="#">Privacy Policy</Link>
                            </Tooltip>
                            <Tooltip title={"Terms of Use"}>
                                <Link variant="footerLink"
                                    onClick={(e) => OnLinkClicked(e)}
                                    color={theme.colors.colorBlackbrownLight} underline="none" href="#">Terms of Use</Link>
                            </Tooltip>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box sx={{
                            display: "flex", flex: 1, height: "100%", mr: 1,
                            alignItems: "center", justifyContent: "end"
                        }}>
                            <Typography variant="poweredBy2">
                                Moham Capital Pvt Ltd © {new Date().getFullYear()} | All rights reserved.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Component