import * as React from "react";
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { MenuItem, Typography } from "@mui/material";

const Component = (props) => {

    const { mode, id, name, value, nameId, valueId, contentId, sx, style, placeHolder,
        validators, validationMessages, options, onDropDownChange } = props;

    const tNameId = nameId || 'text';
    const tValueId = valueId || 'value';
    const tContentId = contentId || tNameId;

    const [inputValue, setInputValue] = React.useState(value || 'NONE');

    React.useEffect(() => {
        ValidatorForm.addValidationRule('isNone', (value) => {
            if (value === 'NONE') return false;
            return true;
        });
    })

    let tmpDefault = { id: -1, [tNameId]: `Select ${placeHolder}`, [tValueId]: `NONE` };
    let listItems = options || [];
    listItems = listItems.filter((x) => x.id > -1);
    listItems.splice(0, 0, tmpDefault);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const lValue = value === 'NONE' ? "" : value;
        setInputValue(value);
        if (onDropDownChange) {
            onDropDownChange({ name, value: lValue });
        }
    }

    if (mode && mode === 'view') {
        let tValue = options.find((x) => parseInt(x[valueId]) === parseInt(value)) || "";
        if (tValue) tValue = tValue[contentId];
        return (
            <>
                <Typography nowrap="true">{tValue}</Typography>
            </>
        )
    }

    return (
        <>
            <SelectValidator
                onChange={handleChange}
                onBlur={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
                autoComplete="off"
                id={id}
                size="small"
                name={name}
                value={(listItems && listItems.length > 0 && inputValue) || "NONE"}
                validators={validators}
                errorMessages={validationMessages}
                InputLabelProps={{ shrink: false }}
                fullWidth
                style={{
                    width: "100%",
                    ...style
                }}
                sx={{
                    "& label": {
                        display: "none"
                    },
                    "&.MuiSelect-select": {
                        borderWidth: "1px",
                        borderColor: "rgb(211, 47, 47)"
                    },
                    ...sx
                }}
            >
                {listItems && listItems.map((x, index) => (
                    <MenuItem key={1000 + index} value={x[tValueId]} name={x[tNameId]}>
                        {x[tContentId]}
                    </MenuItem>
                ))}
            </SelectValidator>
            {/* <InputLabel sx={{
                color: "#d32f2f",
                fontFamily: "Arial",
                fontSize: "0.75rem"
            }}>{validationMessages[0]}</InputLabel> */}
        </>
    );
}

export default Component;
