import * as React from "react";
import { ValidatorComponent, ValidatorForm } from 'react-material-ui-form-validator';
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

class RadioGroupValidator extends ValidatorComponent {

    renderValidatorComponent() {
        const { error, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        const { isValid } = this.state;
        return (
            <>
                <ToggleButtonGroup
                    {...rest}
                    error={(!isValid && errorMessages[0]) || undefined}
                />
                {this.errorText()}
            </>
        );
    }

    errorText() {
        const { isValid } = this.state;
        const { errorMessages } = this.props;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: "#d32f2f", marginLeft: "2px", fontSize: "0.75rem", fontWeight: 400 }}>
                {errorMessages[0]}
            </div>
        );
    }
}

const Component = (props) => {

    const { id, name, value, nameId, valueId, sx, style,
        validators, validationMessages, options, OnInputChange } = props;

    const [inputValue, setInputValue] = React.useState(value);

    const tNameId = nameId || 'text';
    const tValueId = valueId || 'value';

    React.useEffect(() => {
        ValidatorForm.addValidationRule('isOption', (value) => {
            if (value) return true;
            return false;
        });
    })

    const handleChange = (e, value) => {
        setInputValue(value);
        if (OnInputChange) OnInputChange({ name, value });
    }

    return (
        <>
            <RadioGroupValidator
                id={id}
                name={name}
                value={inputValue}
                exclusive
                onChange={handleChange}
                validators={validators}
                errorMessages={validationMessages}
                style={{
                    ...style
                }}
                sx={{
                    ...sx
                }}
            >
                {options && options.map((x) => (
                    <ToggleButton size="small" key={x.id} value={x[tValueId]}>{x[tNameId]}</ToggleButton>
                ))}
            </RadioGroupValidator>

        </>
    );
}

export default Component;
