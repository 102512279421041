import React from 'react'
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Pagination as MuiPagination } from '@mui/material';

const ROWSPERPAGE = [5, 10, 15, 20, 25, 30];

const Pagination = (props) => {
    const { page, onPageChange, className, count, rowsPerPage } = props;
    const pageCount = Math.ceil(count / rowsPerPage);
    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}

function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const Component = (props) => {
    const { columns, rowsCount, rows, pageInfo,
        gridActions, onPageChange, sx, ...rest } = props;

    const handlePaginationModel = (e) => { if (onPageChange) onPageChange(e); }

    return (
        <>
            <DataGrid
                rowHeight={30}
                autoHeight
                disableColumnMenu
                disableColumnSelector
                disableRowSelectionOnClick
                columns={[...columns, gridActions || []]}
                rowCount={rowsCount}
                rows={rows || []}
                rowSelection={false}
                paginationModel={pageInfo}
                initialState={{
                    pagination: {
                        paginationModel: pageInfo,
                    },
                }}
                paginationMode="server"
                onPaginationModelChange={handlePaginationModel}
                pageSizeOptions={ROWSPERPAGE}
                sx={{ ...sx }}
                {...rest}
                pagination
                slots={{
                    pagination: CustomPagination,
                }}
            />
        </>
    )
}

export default Component