import React from "react";
import { CssBaseline, Box } from '@mui/material';
import RendorRoutes from "routes";
import { Notifications } from 'components';

global.Busy = (bBool) => {
  var x = document.getElementById("busyloader");
  if (x) x.className = bBool ? "loader display-block" : "loader display-none";
};

global.AlertPopup = (type, msg) => {
  sessionStorage.setItem('alert', JSON.stringify({ msg, type }));
};

const DefaultToken = () => {
  sessionStorage.setItem("userToken", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjY0ZDA1NmFhNDI2MTQyNGYyNTRkNGRmZCIsImVtYWlsSWQiOiJhZG1pbkBtb2hhbWNhcGl0YWwuaW4iLCJuYW1lIjoiQWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiJ9LCJpYXQiOjE2OTcxNjY3MTksImV4cCI6MTcwMDc2NjcxOSwic3ViIjoiNjRkMDU2YWE0MjYxNDI0ZjI1NGQ0ZGZkIn0.CQHdCMiHcM8H8wMf8O-L6jZZsmdss_tkASnvhsxIixc");
  sessionStorage.setItem("user", "Administrator");
  sessionStorage.setItem("role", "admin");
}

function App() {
  //DefaultToken();

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <RendorRoutes />
      </Box>
      <Notifications />
    </>
  );
}

export default App;